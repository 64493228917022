import {
    Card, Container, Stack,
    Menu, Typography, Button,
    FormControl, Select, MenuItem,
    InputLabel, TextField, InputAdornment,
    CardContent, IconButton, CardHeader, Box,
    Modal,
    Backdrop,
    Fade,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    styled,
    Input
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import CreatingFormsView from './Views/CreatingFormsView';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AddIcon from '@mui/icons-material/Add';
import Pagination from '@mui/material/Pagination';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import DotsColor from 'src/components/color-utils/DotsColor';
import FormsStep1 from './Views/FormsStep1';
import FormsStep2 from './Views/FormsStep2';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import '../pages/createForm.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import api from 'src/services/BaseService';
import moment from 'moment';
import FilterButton from 'src/components/shared/FilterButton';
import { set } from 'lodash';
import ClearButton from 'src/components/shared/ClearButton';
import { toast } from 'react-toastify';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useNavigate } from 'react-router-dom';



const CreateForm = () => {

    const navigate = useNavigate();

    const [formStep1, setFormStep1] = useState({
        surveyTitle: '',
        surveyCategories: '',
        surveyDesc: ''
    });

    const NoHoverButton = styled(Button)({
        '&:hover': {
            backgroundColor: 'transparent', // No hover effect
            boxShadow: 'none',
        },
    });

    const [activeStatus, setActiveStatus] = useState('all');
    const [surveyList, setSurveyList] = useState([]);
    const [title, setTitle] = useState('');
    const [startDate, setStartDate] = useState();
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [openDialogForm, setOpenDialogForm] = useState(false);
    const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);
    const [editData, setEditData] = useState({});
    const [isFormEdit, setIsFormEdit] = useState(false);
    const [originalData, setOriginalData] = useState({});

    const handleCloseModalConfirm = () => {
        setOpenCloseConfirmation(false);
        setOpenModal(false);
        setIsFormEdit(false);
    };

    const handleCloseConfirmation = () => {
        setOpenCloseConfirmation(false);
    };
    const handleOpenCloseConfirmation = () => {
        setOpenCloseConfirmation(true);
    };
    const handleOpenDialog = () => {
        setOpenDialogForm(true);
    };

    const handleCloseDialog = () => {
        setOpenDialogForm(false);

    };

    const handleConfirm = () => {
        setActiveStep(activeStep - 1);
        handleCloseDialog();
        setIsFormEdit(false);
    };

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => {
        setOpenModal(true);
        setActiveStep(0);
    };
    const handleCloseModal = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpenModal(false);
        }
    };
    const [subAnchorEl, setSubAnchorEl] = useState(null);

    const handleClickSubMenu = (event) => {
        setSubAnchorEl(event.currentTarget);
    };

    const handleSubMenuClose = () => {
        setSubAnchorEl(null);
    };
    const [page, setPage] = useState(1);
    const itemsPerPage = 12;

    function changeSurverStatus(status, message) {
        setLoading(true);
        api
            .post('flow/survey-update', {
                surveyID: editData.surveyID,
                status: status,
            })
            .then((response) => {
                setLoading(false);
                if (response.data.status) {
                    toast.success(message);
                    getSurveys([1, 2, 3]); //eklenen verinin render edilip güncellenmesi için
                } else {
                    toast.error("Bir hata oluştu. Lütfen tekrar deneyin.");
                }
            })
            .catch((error) => console.log(error.message));
    }
    function getSurveys(stat=[1,2,3]) {
        api
            .post('flow/get-surveys', { status: stat })
            .then((response) => {
                if (response.data.status) {
                    console.log("responseter", response)
                    setSurveyList(response.data.result);
                    setOriginalData(response.data.result);
                } else {
                    console.log("response-error", response);
                }
            })
            .catch((error) => console.log(error.message));
    }


    useEffect(() => {
        getSurveys([1, 2, 3]);
    }, []);


    const paginatedData = surveyList.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleExpandClick = (event, survey) => {
        setAnchorEl(event.currentTarget);
        setEditData(survey);
    };

    const deleteHandleForm = () => {
        changeSurverStatus(0, 'Anket başarıyla Silindi!');
    }
    const viewHandleForm = () => {
        //console.log("editData.surveyID", editData.surveyID);
        //navigate(`/survey/${editData.surveyID}`);
        const surveyUrl = `/survey/${editData.surveyID}`;
        window.open(surveyUrl, '_blank');
    }
    const AnalysisHandle = () => {
        const surveyUrl = `/dashboard/survey-responses/${editData.surveyID}`;
        navigate(surveyUrl);
        //window.open(surveyUrl, '_blank');
    }
    const changeHandleStatus = (stat) => {
        changeSurverStatus(stat, 'Anket Başarıyla Güncellendi');
    }


    const handleClose = () => {
        setAnchorEl(null);
        setSubAnchorEl(null);
    };

    const handleEdit = () => {
        setIsFormEdit(true);
        setOpenModal(true);
    };
    const getFilteredSurveyList = () => {
        let filteredSurveyList;
        if (title || startDate) {
            filteredSurveyList = originalData.filter((item) => {
                const titleMatch = title
                    ? item.title
                        .toLowerCase()
                        .normalize('NFKD')
                        .replace(/[\u0300-\u036F]/g, '')
                        .includes(
                            title
                                .toLowerCase()
                                .normalize('NFKD')
                                .replace(/[\u0300-\u036F]/g, '')
                        )
                    : true;
                const dateMatch =
                    startDate
                        ? moment.unix(item.createdDateTime).format('DD-MM-YYYY') >=
                        moment(startDate).format('DD-MM-YYYY')
                        : true;
                return titleMatch && dateMatch

            })
        } else {
            filteredSurveyList = originalData;
        }
        setSurveyList(filteredSurveyList)
    }
    const clearFilters = () => {
        setTitle('');
        setStartDate(null);
    }
    const handleFileUpload = (event) => {
        console.log("event", event);
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const surveyData = JSON.parse(e.target.result);
                createSurveyFromUpload(surveyData);
            } catch (error) {
                console.error('Geçersiz JSON dosyası:', error);
                toast.error('Geçersiz dosya. Lütfen doğru bir JSON dosyası yükleyin.');
            }
        };
        reader.readAsText(file);
    };
    const createSurveyFromUpload = async (surveyData) => {
        console.log("surveyData", surveyData);
        setLoading(true);
        try {
            const response = await api.post('flow/survey-create', surveyData);
            setLoading(false);
            console.log("kaan", response)
            if (response.data.status) {
                toast.success('Anket başarıyla yüklendi!');
                getSurveys([1, 2, 3]);
            } else {
                toast.error('Anket yüklenemedi. Lütfen tekrar deneyin.');
            }
        } catch (error) {
            console.error(error.message);
            toast.error('Bir hata oluştu.');
            setLoading(false);
        }
    };
    return (
        <Container maxWidth="xl">

            <Card sx={{ boxShadow: '2px 1px 4px 0px rgba(0, 0, 0, 0.1)' }}>
                <Stack spacing={4} padding={4}>
                    <Stack>
                        <Typography variant="h3">Anket Oluşturma Paneli</Typography>
                    </Stack>
                    <Stack flexDirection="row" justifyContent="space-between" sx={{ paddingLeft: '11px', paddingRight: '11px' }}>
                        <FormControl sx={{ flex: 1 }}>
                            <TextField
                                variant="outlined"
                                placeholder="Anket yada kategori adı bulun.."
                                sx={{
                                    width: '300px', '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'white',
                                    }
                                }}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />

                        </FormControl>
                        <Stack flexDirection="row">
                            <NoHoverButton
                                onClick={() => {
                                    getSurveys([1, 2, 3]);
                                    setActiveStatus('all');
                                }}>
                                <DotsColor specStyle={{ backgroundColor: activeStatus === 'all' ? '#DC423C' : 'transparent', color: activeStatus === 'all' ? 'white' : 'black', }} color='black' name={"Tümü"} border={true} />
                            </NoHoverButton>
                            <NoHoverButton
                                onClick={() => {
                                    getSurveys(1);
                                    setActiveStatus(1);
                                }}>
                                <DotsColor specStyle={{ backgroundColor: activeStatus === 1 ? '#DC423C' : 'transparent', color: activeStatus === 1 ? 'white' : '#067647' }} color='#067647' name={"Aktif"} border={true} />
                            </NoHoverButton>
                            <NoHoverButton
                                onClick={() => {
                                    getSurveys(2);
                                    setActiveStatus(2);
                                }}>
                                <DotsColor specStyle={{ backgroundColor: activeStatus === 2 ? '#DC423C' : 'transparent', color: activeStatus === 2 ? 'white' : 'rgb(222 126 2)' }} color='rgb(222 126 2)' name={"İptal"} border={true} />
                            </NoHoverButton>
                            <NoHoverButton
                                onClick={() => {
                                    getSurveys(3);
                                    setActiveStatus(3);
                                }}>
                                <DotsColor specStyle={{ backgroundColor: activeStatus === 3 ? '#DC423C' : 'transparent', color: activeStatus === 3 ? 'white' : 'gray' }} color='gray' name={"Taslak"} border={true} />
                            </NoHoverButton>
                        </Stack>

                        <Stack flexDirection="row" sx={{ gap: '15px' }}>
                            <FormControl>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        sx={{
                                            backgroundColor: '#ed9a9740', '& .MuiOutlinedInput-root': {
                                                backgroundColor: 'white',
                                            }
                                        }}
                                        format="DD/MM/YYYY"
                                        label="Tarih Seçin"
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <Button variant='contained' endIcon={<AddIcon />} onClick={handleOpenModal}> Anket Oluştur</Button>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                <Input
                                    type="file"
                                    accept=".json"
                                    onChange={handleFileUpload}
                                    style={{ display: 'none' }}
                                    id="file-upload"
                                />
                                <label style={{ height: '100%' }} htmlFor="file-upload">
                                    <Button
                                        sx={{ height: '100%' }}
                                        variant="contained"
                                        component="span"
                                        startIcon={<UploadFileIcon />}
                                    >
                                        {"İçe Aktar"}
                                    </Button>
                                </label>
                            </div>
                        </Stack>
                    </Stack>
                    <Stack sx={{ paddingLeft: '11px', gap: '10px' }}>
                        <Divider sx={{ my: '5px !important' }} />
                        <Stack flexDirection="row" sx={{ gap: '10px' }}>
                            <FilterButton onClick={() => getFilteredSurveyList()} />
                            <ClearButton onClick={() => clearFilters()} />
                        </Stack>
                    </Stack>


                    <Stack flexDirection="row" sx={{ gap: '15px' }} justifyContent="center" flexWrap="wrap" >
                        {paginatedData.map((survey, i) => (
                            <Card key={i} sx={{ maxWidth: 400, width: '340px', border: '1px solid #f1dbdb' }}>

                                <CardHeader
                                    avatar={
                                        <img
                                            src={require('../../src/assets/images/form_frame_stage.png')}
                                            alt="Pop-up Error"
                                            style={{
                                                display: 'block',
                                                margin: '20px auto',
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                    }
                                    action={
                                        <IconButton onClick={(event) => handleExpandClick(event, survey)} aria-label="settings">
                                            <MoreHorizIcon />
                                        </IconButton>
                                    }


                                />
                                <CardContent>
                                    <Typography variant="h5" sx={{ color: 'text.primary' }}>
                                        {survey.title}
                                    </Typography>
                                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                                        <Typography variant="p" sx={{ color: 'text.secondary' }}>
                                            {survey.category} -{moment.unix(survey.createdDateTime).format('YYYY-MM-DD')}
                                        </Typography>
                                        {survey.status === '1' && <DotsColor color='#067647' name={"Aktif"} border={true} />}
                                        {survey.status === '2' && <DotsColor color='rgb(222 126 2)' name={"İptal"} border={true} />}
                                        {survey.status === '3' && <DotsColor color='gray' name={"Taslak"} border={true} />}

                                    </Stack>

                                </CardContent>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <Box sx={{ width: '250px' }}>

                                        <MenuItem
                                            onClick={handleClose}
                                            disableRipple
                                            sx={{ display: 'block' }}

                                        >
                                            <IconButton onClick={handleEdit} sx={{
                                                fontSize: '16px', color: 'black', display: 'flex', width: '100%', justifyContent: 'space-between', '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    opacity: 1,
                                                },
                                            }}>
                                                Düzenle
                                                <EditIcon sx={{ marginRight: '4px' }} />

                                            </IconButton>
                                        </MenuItem>
                                        <Divider sx={{ my: '5px !important' }} />

                                        <MenuItem onClick={handleClose} disableRipple sx={{ display: 'block' }} >
                                            <IconButton onClick={deleteHandleForm} sx={{
                                                fontSize: '16px', color: 'black', display: 'flex', width: '100%', justifyContent: 'space-between', '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    opacity: 1,
                                                },
                                            }}>
                                                Sil
                                                <DeleteIcon />

                                            </IconButton>

                                        </MenuItem>
                                        <MenuItem onClick={handleClose} disableRipple sx={{ display: 'block' }} >
                                            <IconButton onClick={viewHandleForm} sx={{
                                                fontSize: '16px', color: 'black', display: 'flex', width: '100%', justifyContent: 'space-between', '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    opacity: 1,
                                                },
                                            }}>
                                                Görüntüle
                                                <VisibilityIcon />
                                            </IconButton>
                                        </MenuItem>
                                        <MenuItem onClick={handleClose} disableRipple sx={{ display: 'block' }} >
                                            <IconButton onClick={AnalysisHandle} sx={{
                                                fontSize: '16px', color: 'black', display: 'flex', width: '100%', justifyContent: 'space-between', '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    opacity: 1,
                                                },
                                            }}>
                                                Anket Sonuçları
                                                <AssessmentIcon />
                                            </IconButton>
                                        </MenuItem>
                                        <Divider sx={{ my: '5px !important' }} />

                                        {/* Status Alt Menüsü */}
                                        <MenuItem
                                            aria-controls="status-menu"
                                            aria-haspopup="true"
                                            onClick={handleClickSubMenu}
                                            disableRipple
                                            sx={{ display: 'block' }}
                                        >
                                            <IconButton sx={{
                                                fontSize: '16px', color: 'black', display: 'flex', width: '100%', justifyContent: 'space-between', '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    opacity: 1,
                                                },
                                            }}>
                                                Statü
                                                <KeyboardArrowRightIcon />

                                            </IconButton>
                                        </MenuItem>

                                        <Menu
                                            id="status-menu"
                                            anchorEl={subAnchorEl} // Alt menü için ayrı anchor
                                            open={Boolean(subAnchorEl)} // Alt menünün açık olup olmadığını kontrol ediyoruz
                                            onClose={handleSubMenuClose} // Alt menü kapandığında çalışacak fonksiyon
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Box sx={{ minWidth: '200px' }}>
                                                <MenuItem onClick={() => {
                                                    handleClose();
                                                    changeHandleStatus(1);
                                                }} disableRipple sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography  >
                                                        Aktif
                                                    </Typography>
                                                    <DotsColor color='#067647' border={false} />
                                                </MenuItem>
                                                <MenuItem onClick={() => {
                                                    handleClose();
                                                    changeHandleStatus(2);
                                                }} disableRipple sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="p" sx={{ color: 'text.primary' }}>
                                                        İptal

                                                    </Typography>
                                                    <DotsColor color='rgb(222 126 2)' border={false} />
                                                </MenuItem>
                                                <MenuItem onClick={() => {
                                                    handleClose();
                                                    changeHandleStatus(3);
                                                }} disableRipple sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="p" sx={{ color: 'text.primary' }}>
                                                        Taslak
                                                    </Typography>
                                                    <DotsColor color='gray' border={false} />
                                                </MenuItem>
                                            </Box>
                                        </Menu>

                                    </Box>
                                </Menu>

                            </Card>
                        ))}

                    </Stack>
                </Stack>
                <Box display="flex" justifyContent="center" sx={{ mt: 2, mb: 2 }}>
                    <Pagination

                        count={Math.ceil(surveyList.length / itemsPerPage)}
                        page={page}
                        onChange={handleChange}
                        shape="rounded"
                        sx={{
                            "& .Mui-selected": {
                                backgroundColor: '#da3831 !important',
                                color: 'white !important',
                            },
                            "& .MuiPaginationItem-root": {
                                color: '#E0735A',
                                borderColor: '#da3831',
                                fontSize: '1.10rem',
                                width: '42px',
                                height: '42px'
                            },
                            "& .MuiPaginationItem-root:hover": {
                                backgroundColor: '#f9cbc9',
                            },
                            "& .MuiPaginationItem-previousNext": {
                                border: '1px solid #E0735A'
                            }


                        }}
                    />
                </Box>
            </Card>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        border: '1px solid white',
                        boxShadow: 24,
                        p: 4,
                        width: activeStep === 0 ? '28%' : '60%',
                        borderRadius: '6px',
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}>
                        <Stack sx={{ gap: '10px' }}>

                            {activeStep === 0 && <FormsStep1 isFormEdit={isFormEdit} editData={editData} setFormStep1={setFormStep1} formStep1={formStep1} activeStep={activeStep} handleOpenCloseConfirmation={handleOpenCloseConfirmation} setActiveStep={setActiveStep} />}
                            {activeStep === 1 && <FormsStep2 setOpenModal={setOpenModal} isFormEdit={isFormEdit} editData={editData} formStep1={formStep1} handleOpenCloseConfirmation={handleOpenCloseConfirmation} getSurveys={getSurveys} />}

                            < >
                                {activeStep === 1 &&
                                    <button type='button' onClick={handleOpenDialog}
                                        style={{ cursor: 'pointer', height: '45px', width: '45px', position: 'absolute', top: '5px', left: '10px', background: 'transparent', border: 0 }} variant="contained" color="primary">
                                        <ArrowBackIosIcon />
                                    </button>
                                }
                            </>
                        </Stack>
                        <Dialog
                            open={openDialogForm}
                            onClose={handleCloseDialog}
                            aria-labelledby="confirm-dialog-title"
                            aria-describedby="confirm-dialog-description"
                        >
                            <DialogTitle id="confirm-dialog-title">Önceki Adıma Dön</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="confirm-dialog-description">
                                    Değişiklikleriniz kaydedilmedi Önceki adıma dönmek istediğinize emin misiniz?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">
                                    İptal
                                </Button>
                                <Button onClick={handleConfirm} color="primary" autoFocus>
                                    Evet
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openCloseConfirmation} onClose={handleCloseConfirmation} aria-labelledby="close-dialog-title" aria-describedby="close-dialog-description">
                            <DialogTitle id="close-dialog-title">Anketi Kapat</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="close-dialog-description">
                                    Anketi kapatmak istediğinize emin misiniz?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseConfirmation} color="primary">
                                    İptal
                                </Button>
                                <Button onClick={handleCloseModalConfirm} color="primary" autoFocus>
                                    Evet
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Fade>
            </Modal>
        </Container>
    )

}

export default CreateForm
