import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import RatingChart from './RatingChart';
import MultiChoiceChart from './MultiChoiceChart';
import ShortAnswerChart from './ShortAnswerChart';
import { useLocalization } from 'src/context/LocalizationContext';
import api from 'src/services/BaseService';

const QuestionAnalyzer = ({ responses }) => {
    const { language } = useLocalization();
    const [summary, setSummary] = useState({
        multi_choice: {},
        rating: {},
        short_answer: {}
    });

    const calculateSummary = () => {
        const groupedSummary = {
            multi_choice: {},
            rating: {},
            short_answer: {}
        };

        responses.map((survey) => {
            //console.log("survey", survey)
            survey.response.map((question) => {
                const { type, question: questionText, response, options, maxRating } = question;

                // Burada null ve undefined değerlerini yok sayıyoruz
                if (response === null || response === undefined) {
                    return;
                }
                // Kısa cevap soruları için

                if (type === "short_answer") {

                    if (!groupedSummary.short_answer[questionText]) {
                        groupedSummary.short_answer[questionText] = {
                            totalResponses: 0,
                            details: {}
                        };
                    }
                    groupedSummary.short_answer[questionText].totalResponses += 1;
                    groupedSummary.short_answer[questionText].details[response] =
                        (groupedSummary.short_answer[questionText].details[response] || 0) + 1;

                } else if (type === "multi_choice") {
                    if (!groupedSummary.multi_choice[questionText]) {
                        groupedSummary.multi_choice[questionText] = {
                            totalResponses: 0,
                            details: {}
                        };

                        options.forEach(option => {
                            groupedSummary.multi_choice[questionText].details[option] = 0;
                        });
                    }
                    groupedSummary.multi_choice[questionText].totalResponses += 1;
                    groupedSummary.multi_choice[questionText].details[response] =
                        (groupedSummary.multi_choice[questionText].details[response] || 0) + 1;

                } else if (type === "rating") {
                    if (!groupedSummary.rating[questionText]) {
                        groupedSummary.rating[questionText] = {
                            totalResponses: 0,
                            details: {}
                        };

                        const ratingRange = maxRating || 5; // maxRating verilmemişse varsayılan olarak 5 kabul edelim
                        for (let i = 1; i <= ratingRange; i++) {
                            groupedSummary.rating[questionText].details[i] = 0;
                        }
                    }
                    groupedSummary.rating[questionText].totalResponses += 1;
                    groupedSummary.rating[questionText].details[response] =
                        (groupedSummary.rating[questionText].details[response] || 0) + 1;
                }
            });
        });

        setSummary(groupedSummary);
    };

    useEffect(() => {
        calculateSummary();
    }, [responses]);


    // console.log("responses", responses)

    // function exportToExcel() {
    //     // Build the export data dynamically from the summary state
    //     const multiChoiceData = Object.entries(summary.multi_choice).map(([question, data], index) => ({
    //         question: question,
    //         totalResponses: data.totalResponses,
    //         options: Object.entries(data.details).map(([option, count]) => `${option}: ${count}`).join(", ")
    //     }));

    //     const ratingData = Object.entries(summary.rating).map(([question, data], index) => ({
    //         question: question,
    //         totalResponses: data.totalResponses,
    //         ratings: Object.entries(data.details).map(([rating, count]) => `${rating}: ${count}`).join(", ")
    //     }));

    //     const shortAnswerData = Object.entries(summary.short_answer).map(([question, data], index) => ({
    //         question: question,
    //         totalResponses: data.totalResponses,
    //         responses: Object.entries(data.details).map(([response, count]) => `${response}: ${count}`).join(", ")
    //     }));

    //     // Combine all data into a single array for export
    //     const exportData = [
    //         ...multiChoiceData.map(item => ({
    //             type: 'Multi-Choice',
    //             question: item.question,
    //             totalResponses: item.totalResponses,
    //             details: item.options
    //         })),
    //         ...ratingData.map(item => ({
    //             type: 'Rating',
    //             question: item.question,
    //             totalResponses: item.totalResponses,
    //             details: item.ratings
    //         })),
    //         ...shortAnswerData.map(item => ({
    //             type: 'Short Answer',
    //             question: item.question,
    //             totalResponses: item.totalResponses,
    //             details: item.responses
    //         }))
    //     ];

    //     // Format the data to match the structure expected by the API
    //     const formattedData = exportData.map((x, index) => ({
    //         id: index + 1,
    //         type: x.type,
    //         question: x.question,
    //         totalResponses: x.totalResponses,
    //         details: x.details
    //     }));

    //     // Send the data to the API for Excel export
    //     api
    //         .post('excel/export', {
    //             type: 'excel',
    //             content: formattedData,
    //             header: {
    //                 No: 'number',
    //                 Type: 'string',
    //                 Question: 'string',
    //                 TotalResponses: 'string',
    //                 Details: 'string',
    //             },
    //             sheetTitle: 'Survey Results',
    //         })
    //         .then((response) => {
    //             if (response.data.status) {
    //                 try {
    //                     const newWindow = window.open(response.data.result, '_blank');
    //                     if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
    //                         setShowPopUpError(true);
    //                         throw new Error('Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.');
    //                     }
    //                 } catch (e) {
    //                     setShowPopUpError(true);
    //                 }
    //             } else {
    //                 toast.error(response.data.message);
    //             }
    //         })
    //         .catch((error) => {
    //             toast.error(error.message);
    //         });
    // }


    function exportToExcel() {
        let sharedQuestions = responses[0].response.map(e => e.question);
        let persons = responses.map((e) => {
            const selectAnswer = e.response.map(ele => ele.response);
            return { questions: selectAnswer };
        });

        // console.log("persons", persons);
        // console.log("sharedQuestions", sharedQuestions);


        const formattedData = sharedQuestions.map((question, questionIndex) => {
            const row = { question }; 
            persons.forEach((person, personIndex) => {
                const answer = person.questions[questionIndex];
                row[`Kişi ${personIndex + 1}`] = Array.isArray(answer) ? answer.join(", ") : answer;
            });
            return row;
        });

        // console.log("formattedData", formattedData);
        api
            .post('excel/export', {
                type: 'excel',
                content: formattedData,
                header: {
                    Question: 'string',
                    ...Object.fromEntries(
                        persons.map((_, i) => [`Person ${i + 1}`, 'string'])
                    )
                },
                sheetTitle: 'Anket Sonuçları',
            })
            .then((response) => {
                if (response.data.status) {
                    try {
                        const newWindow = window.open(response.data.result, '_blank');
                        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
                            //setShowPopUpError(true);
                            throw new Error('Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.');
                        }
                    } catch (e) {
                        //setShowPopUpError(true);
                    }
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }


    return (
        <Box sx={{ paddingLeft: '15px' }}>
            {/* Çoktan Seçmeli Sorular */}
            <Box>
                <h3>{language.commons.surveys.multi_choice}</h3>
                <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 5, sm: 15 }} paddingX={3}>
                    <MultiChoiceChart
                        summary={summary}
                    />
                </Stack>
            </Box>
            {/* Derecelendirme Soruları */}
            <Box>
                <Box>
                    <h3>{language.commons.surveys.rating}</h3>
                    <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 5, sm: 15 }} paddingX={3}>
                        <RatingChart
                            summary={summary.rating}
                        />
                    </Stack>
                </Box>
            </Box>
            {/* Kısa Cevap Soruları */}
            <Stack>
                <h3>{language.commons.surveys.short_answer}</h3>
                <Stack flexDirection="row" gap={20}>
                    <ShortAnswerChart
                        summary={summary}
                    />
                </Stack>
            </Stack>

        
        </Box>
    );
};

export default QuestionAnalyzer;
