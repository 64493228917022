import {
    Card,
    Stack,
    Typography,
    Divider,
    Button
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'src/services/BaseService';
import QuestionAnalyzer from 'src/components/survey/QuestionAnalyzer';
import { useLocalization } from 'src/context/LocalizationContext';

//import Services from 'src/services/Services'; 

const SurveyResponses = () => {
    const { language } = useLocalization();
    const [surveyList, setSurveyList] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    //const [userData, setUserData] = useState([]);

    const { id } = useParams();

    const fetchSurveyAndUsers = async () => {
        try {
            const surveyResponse = await api.post('flow/get-survey-responses', { surveyID: id });
            if (surveyResponse.data.status) {
                setOriginalData(surveyResponse.data.result);
                setSurveyList(surveyResponse.data.result);
            }
        } catch (error) {
            console.error("Error fetching survey or user data:", error);
        }
    };

    useEffect(() => {
        fetchSurveyAndUsers();
    }, []);

    function exportToExcel() {
        let sharedQuestions = surveyList[0].response.map(e => e.question);
        let persons = surveyList.map((e) => {
            const selectAnswer = e.response.map(ele => ele.response);
            return { questions: selectAnswer };
        });

        // console.log("persons", persons);
        // console.log("sharedQuestions", sharedQuestions);


        const formattedData = sharedQuestions.map((question, questionIndex) => {
            const row = { question };
            persons.forEach((person, personIndex) => {
                const answer = person.questions[questionIndex];
                row[`Kişi ${personIndex + 1}`] = Array.isArray(answer) ? answer.join(", ") : answer;
            });
            return row;
        });

        // console.log("formattedData", formattedData);
        api
            .post('excel/export', {
                type: 'excel',
                content: formattedData,
                header: {
                    Question: 'string',
                    ...Object.fromEntries(
                        persons.map((_, i) => [`${i + 1}.Kişi`, 'string'])
                    )
                },
                sheetTitle: 'Anket Sonuçları',
            })
            .then((response) => {
                if (response.data.status) {
                    try {
                        const newWindow = window.open(response.data.result, '_blank');
                        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
                            //setShowPopUpError(true);
                            throw new Error('Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.');
                        }
                    } catch (e) {
                        //setShowPopUpError(true);
                    }
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }

    return (
        <Stack style={{ paddingInline: '25px' }}>

            <Card sx={{ mb: 3, p: 2 }}>
                <Stack flexDirection="row" justifyContent="space-between">
                    <Typography variant="h4" sx={{ mb: 4 }}>
                        {language.commons.surveys.survey_results}
                    </Typography>
                    <Button sx={{width:'170px',height:'50px'}} onClick={exportToExcel} variant="contained">
                        Excel Olarak İndir
                    </Button>
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <QuestionAnalyzer responses={surveyList} />
            </Card>

        </Stack>
    );
};

export default SurveyResponses;
