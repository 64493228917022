import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
// @mui
import {
  Card,
  Stack,
  Button,
  MenuItem,
  Typography,
  TextField,
  FormControl,
  Select,
  OutlinedInput,
  Modal,
  Box,
  InputLabel,
  Autocomplete,
  useMediaQuery,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel'; // Çarpı simgesi için
import PrintIcon from '@mui/icons-material/Print';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
// sections
import api from 'src/services/BaseService';
import './muhaberatOutput.css';
// ----------------------------------------------------------------------
import { StatusList } from 'src/types/statusTypes';
import { CreatedUsersTypes } from 'src/types/createdUsersTypes';
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import Iconify from 'src/components/iconify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useLocalization } from 'src/context/LocalizationContext';
import Services from 'src/services/Services';
import DotsColor from 'src/components/color-utils/DotsColor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import { Download } from '@mui/icons-material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const all = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

const sendRequestToService = async (item) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // Simüle edilmiş başarı durumu
      const isSuccess = Math.random() > 0.2; // %80 başarı oranı
      if (isSuccess) {
        resolve({
          id: item.id,
          status: 'success',
          message: `Başarılı işlem: ${item.name}`,
        });
      } else {
        reject({
          id: item.id,
          status: 'error',
          message: `Başarısız işlem: ${item.name}`,
        });
      }
    }, 2000); // 2 saniye bekleme
  });
};

export default function AllActionsPage() {
  const { language } = useLocalization();
  const customer = useSelector((state) => state.customerReducer);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [allDatas, setAllDatas] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedCargoStatus, setSelectedCargoStatus] = useState([]);
  const [barcode, setBarcode] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [orderNo, setOrderNo] = useState('');
  const [point, setPoint] = useState('');
  const [epBranchCode, setEpBranchCode] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [mobileAll, setMobileAll] = useState(false);
  const [pointTitlesOptions, setPointTitlesOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deliveryValue, setDeliveryValue] = useState('');
  const [list, setList] = useState([]);
  const [processedList, setProcessedList] = useState([]); // İşlenen öğeleri burada tutacağız
  const [successCount, setSuccessCount] = useState(0);
  const [failureCount, setFailureCount] = useState(0);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const [openDeliveryReport, setOpenDeliveryReport] = useState(false);

  const [selectedPoint, setSelectedPoint] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const [selectedDeliveryTypes, setSelectedDeliveryTypes] = useState([]);
  const [apiTypes, setApiTypes] = useState('');
  const [showPopUpError, setShowPopUpError] = useState(false);
  const [secondFilter, setSecondFilter] = useState(false);
  const [openDeliverys, setOpenDeliverys] = useState(false);

  const parsedUser = JSON.parse(localStorage.getItem('user'));

  const handleMobileAllOpen = () => setMobileAll(true);
  const handleMobileAllClose = () => setMobileAll(false);
  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const handleCopy = (branchCode) => {
    navigator.clipboard.writeText(branchCode);
  };

  const TABLE_HEAD = [
    { id: 'title', label: language.commons.column.title, alignRight: false },
    {
      id: 'barcode',
      label: language.commons.column.barcode,
      alignRight: false,
    },
    {
      id: 'willTakenBy',
      label: language.commons.column.receiver,
      alignRight: false,
      format: (value) =>
        parsedUser.type === 'esnaf' ? maskName(value) : value,
    },
    {
      id: 'deliverValue',
      label: language.commons.column.condition,
      alignRight: false,
      format: (value, row) => getStatusLabel(+row.status),
    },
    {
      id: 'pointTitle',
      label: language.commons.column.point,
      alignRight: false,
    },
    {
      id: 'ep_branch_code',
      label: language.commons.column.branch_code,
      alignRight: false,
      format: (value, row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: '50px',
          }}
        >
          <span style={{ marginRight: '6px' }}>{row.branchCode}</span>
          {row.branchCode && row.branchCode !== 'NA' && (
            <ContentCopyIcon
              style={{ cursor: 'pointer', color: '#98A2B3' }}
              onClick={() => handleCopy(row.branchCode)}
            />
          )}
        </div>
      ),
    },
    {
      id: 'shipmentFirm',
      label: language.commons.column.cargo_company,
      alignRight: false,
      format: (value, row) =>
        row.shipmentFirm !== '' ? row.shipmentFirm : 'Nakliye',
    },
    {
      id: 'dropOff',
      label: language.commons.column.drop_off,
      alignRight: false,
      format: (value, row) => (row.isDropOff === '0' ? 'Hayır' : 'Evet'),
    },
    {
      id: 'createdBy',
      label: language.commons.column.creator,
      alignRight: false,
      format: (value, row) =>
        row.apiUserType === 'handsfree' &&
        (row.additionalNote?.createdBy?.type === 'store'
          ? language.commons.column.store
          : language.commons.customer),
    },
    {
      id: 'createdDateTime',
      label: language.commons.column.created_date,
      alignRight: false,
      format: (value, row) =>
        moment.unix(row.createdDateTime).format('DD/MM/YYYY'),
    },
    {
      id: 'createdTime',
      label: language.commons.labels.createdDateTimeFormat,
      alignRight: false,
      format: (value, row) => moment.unix(row.createdDateTime).format('HH:mm'),
    },
    {
      id: 'completedDateTime',
      label: language.commons.labels.completedDateTime,
      alignRight: false,
      format: (value, row) =>
        (row.status === '1' || row.status === '17' || row.status === '21') &&
        moment.unix(row.completedDateTime).format('DD/MM/YYYY'),
    },
    {
      id: 'completedTime',
      label: language.commons.labels.completedDateTimeFormat,
      alignRight: false,
      format: (value, row) =>
        (row.status === '1' || row.status === '17' || row.status === '21') &&
        moment.unix(row.completedDateTime).format('HH:mm'),
    },
    {
      id: 'action',
      label: '',
      alignRight: false,
      format: (value, row) => (
        <Tooltip title={language.commons.detail}>
          <Iconify
            style={{
              color: '#98A2B3',
              width: 22,
              height: 22,
              cursor: 'pointer',
            }}
            icon="bx:detail"
            onClick={() =>
              window.open(`/dashboard/post-details/${row.barcode}`, '_blank')
            }
          />
        </Tooltip>
      ),
    },
  ];

  function maskName(name) {
    if (!name) return '';

    const parts = name.split(' ');

    const maskedParts = parts.map((part) => {
      if (part.length <= 2) {
        return part;
      }
      const firstTwoLetters = part.substring(0, 2);
      const remaining = '*'.repeat(part.length - 2);
      return firstTwoLetters + remaining;
    });

    const maskedName = maskedParts.join(' ');

    return maskedName;
  }

  // Service
  const getUniquePointTitles = (data) => {
    const uniqueTitles = new Map();
    data.forEach((item) => {
      if (item.title && !uniqueTitles.has(item.title)) {
        uniqueTitles.set(item.title, { id: item.id, title: item.title });
      }
    });
    return Array.from(uniqueTitles.values());
  };

  function exportToExcel() {
    let temp1 = filteredOrders.map((x, index) => {
      let statusLabel = getStatusLabel(+x.status);
      let createdDateTime = moment.unix(x.createdDateTime).format('DD/MM/YYYY');
      let createdDateHour = moment.unix(x.createdDateTime).format('HH:mm');
      let completedDateTime = x.completedDateTime
        ? moment.unix(x.completedDateTime).format('DD/MM/YYYY')
        : '';
      let completedDateHour = x.completedDateTime
        ? moment.unix(x.completedDateTime).format('HH:mm')
        : '';

      let a = {
        id: index + 1,
        title: x.title,
        barcode: x.barcode,
        willTakenBy: x.willTakenBy,
        status: statusLabel,
        pointTitle: x.pointTitle,
        ep_branch_code: x.ep_branch_code,
        shipmentFirm: x.shipmentFirm,
        createdDateTime: createdDateTime,
        createdDateHour: createdDateHour,
        completedDateTime: completedDateTime,
        completedDateHour: completedDateHour,
      };
      return a;
    });

    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          No: 'number',
          Başlık: 'string',
          Barkod: 'string',
          Alıcı: 'string',
          Durumu: 'string',
          Noktası: 'string',
          'Şube Kodu': 'string',
          'Kargo Firması': 'string',
          'Oluşturulma Tarihi': 'string',
          'Oluşturulma Saati': 'string',
          'Tamamlanma Tarihi': 'string',
          'Tamamlanma Saati': 'string',
        },
        sheetTitle: 'Gönderiler',
      })
      .then((response) => {
        console.log('excel: ', response.data);
        if (response.data.status) {
          try {
            const newWindow = window.open(response.data.result, '_blank');
            if (
              !newWindow ||
              newWindow.closed ||
              typeof newWindow.closed == 'undefined'
            ) {
              setShowPopUpError(true);
              throw new Error(
                'Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.'
              );
            }
          } catch (e) {
            setShowPopUpError(true);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  function getCenterTitles(parsedUser) {
    const titles = [];

    for (const key in parsedUser.allowedPoints) {
      const centers = parsedUser.allowedPoints[key].centers;

      if (Array.isArray(centers)) {
        centers.forEach((center) => {
          titles.push(center.title);
        });
      }
    }
    if (!titles.includes(parsedUser.firstResponsibleOffice.title)) {
      titles.push(parsedUser.firstResponsibleOffice.title);
    }

    return titles;
  }

  function getPoints() {
    api
      .post('flow/get-points')
      .then((response) => {
        if (response.data.status) {
          let pointTitles = getUniquePointTitles(response.data.result);
          console.log('pointTitles: ', pointTitles);
          if (
            parsedUser?.frontAuth?.length > 0 &&
            parsedUser?.frontAuth?.some((auth) =>
              ['manager', 'EP', 'EPS'].includes(auth)
            )
          ) {
            console.log('içeriyor');
            setPointTitlesOptions(pointTitles);
          } else {
            const titles = getCenterTitles(parsedUser);
            let filteredTitles = pointTitles.filter((point) =>
              titles.includes(point.title)
            );
            console.log('sınırlı points');
            setPointTitlesOptions(filteredTitles);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  }
  function getFilteredOrderList() {
    //console.log(parsedUser);
    setSecondFilter(true);
    // console.log(customer?.customer?.branchID, customer?.customer?.id);
    // console.log(selectedCargoStatus);
    console.log('selectedPoint: ', selectedPoint);
    api
      .post('posts/get', {
        // branchID: customer?.customer?.branchID || null,
        // mallID: customer?.customer?.id || null,
        limit: 5000,
        status:
          selectedCargoStatus && selectedCargoStatus?.length > 0
            ? selectedCargoStatus?.map((x) => x.id)
            : StatusList.filter((x) => x.id != 0).map((x) => x.id),
        startDate: startDate
          ? moment(startDate).format('DD-MM-YYYY')
          : startDate,
        endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : endDate,
        sentToEasyPoint: selectedPoint,
        apiType: apiTypes,
        isForced:
          parsedUser?.frontAuth?.length > 0
            ? parsedUser?.frontAuth?.some((auth) =>
                ['EP', 'EPS'].includes(auth)
              )
            : false,
      })
      .then((response) => {
        console.log('posts: ', response.data.result);
        if (response.data.status) {
          // let pointTitles = getUniquePointTitles(response.data.result)
          // setPointTitlesOptions(pointTitles)
          // console.log(response.data.result);
          setOrderList(response.data.result);
          setAllDatas(response.data.result);
          setFilteredData(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  }

  useEffect(() => {
    getPoints();
  }, []);

  function clearFilters() {
    setStartDate(null);
    setEndDate(null);
    setSelectedCargoStatus([]);
    setSelectedDeliveryTypes([]);
    setBarcode('');
    setEpBranchCode('');
    // getFilteredOrderList();
    setFilteredData([]);
    setAllDatas([]);
    setOrderList([]);
    setPage(0);
    setSelectedPoint(null);
    setName('');
    setType('');
    setOrderNo('');
    setPoint('');
    setEpBranchCode('');
    setApiTypes('');
    setSecondFilter(false);
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCargoStatus(value);
  };

  const filterByBarcode = (text) => {
    const _barcode = text.toLowerCase();
    const filteredOrders = allDatas.filter((item) =>
      item.barcode.toLowerCase().includes(_barcode)
    );
    setBarcode(_barcode);
    setOrderList(filteredOrders);
    setFilteredData(filteredOrders);
  };

  const filterByBranchCode = (text) => {
    const _branchCode = text.toLowerCase();
    const filteredOrders = allDatas.filter((item) =>
      item.ep_branch_code.toLowerCase().includes(_branchCode)
    );
    setEpBranchCode(_branchCode);
    setOrderList(filteredOrders);
    setFilteredData(filteredOrders);
  };

  // const filterByOrderNo = (text) => {
  //   const _orderNo = text.toLowerCase();
  //   const filteredOrders = allDatas.filter((item) =>
  //     item.orderNumber?.toLowerCase().includes(_orderNo)
  //   );
  //   setOrderNo(_orderNo);
  //   setOrderList(filteredOrders);
  //   setFilteredData(filteredOrders);
  // };

  // const filterByName = (text) => {
  //   const _name = text.toLowerCase();
  //   const filteredOrders = allDatas.filter((item) =>
  //     item.willTakenBy.toLowerCase().includes(_name)
  //   );
  //   setName(_name);
  //   setOrderList(filteredOrders);
  //   setFilteredData(filteredOrders);
  // };

  // const filterByType = (text) => {
  //   const apiType = text.toLowerCase();
  //   const filteredOrders = allDatas.filter((item) =>
  //     item.apiUserType.toLowerCase().includes(apiType)
  //   );
  //   setType(apiType);
  //   setOrderList(filteredOrders);
  //   setFilteredData(filteredOrders);
  // };

  // const filterByPoint = (text) => {
  //   const _point = text.toLowerCase();
  //   const filteredOrders = allDatas.filter((item) =>
  //     item.pointTitle.toLowerCase().includes(_point)
  //   );
  //   setPoint(_point);
  //   setOrderList(filteredOrders);
  //   setFilteredData(filteredOrders);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setPage(0);
  //   setRowsPerPage(parseInt(event.target.value, 10));
  // };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList?.length) : 0;

  // const isNotFound = !filteredOrders?.length && !!filterName;

  const filteredOrders = applySortFilter(
    filteredData,
    getComparator(order, orderBy),
    filterName
  );

  const getStatusLabel = (statusValue) => {
    const statusObject = StatusList.find((status) => status.id === statusValue);
    return statusObject ? statusObject.label : '';
  };

  const getFilteredActionList = () => {
    let filteredActionList;
    if (barcode || name || type || orderNo || point || epBranchCode) {
      filteredActionList = allDatas.filter((item) => {
        const barcodeMatch = barcode
          ? item.barcode.toLowerCase().includes(barcode.toLowerCase())
          : true;
        const nameMatch = name
          ? item.willTakenBy.toLowerCase().includes(name.toLowerCase())
          : true;
        const typeMatch = type
          ? item.apiUserType.toLowerCase()?.includes(type.toLowerCase())
          : true;
        const orderNoMatch = orderNo
          ? item.orderNumber?.toLowerCase().includes(orderNo.toLowerCase())
          : true;
        const pointMatch = point
          ? item.pointTitle.toLowerCase().includes(point.toLowerCase())
          : true;
        const epBranchCodeMatch = epBranchCode
          ? item.ep_branch_code
              .toLowerCase()
              .includes(epBranchCode.toLowerCase())
          : true;

        return (
          barcodeMatch &&
          nameMatch &&
          typeMatch &&
          orderNoMatch &&
          pointMatch &&
          epBranchCodeMatch
        );
      });
    } else {
      filteredActionList = allDatas;
    }

    setFilteredData(filteredActionList);
  };

  const clearFilterAndInputs = () => {
    setBarcode('');
    setName('');
    setType('');
    setOrderNo('');
    setPoint('');
    setEpBranchCode('');
    setFilteredData(allDatas);
  };
  const handleOpenDelivery = async () => {
    setOpenDeliverys(true);
    //processAllItems();
    setList([]);
    setProcessedList([]);
    setSuccessCount(0);
    setFailureCount(0);
  };
  const handleCloseDelivery = () => setOpenDeliverys(false);

  const handleInputChange = (event) => {
    setDeliveryValue(event.target.value);
  };

  const handleAddToList = () => {
    if (deliveryValue) {
      setList([...list, deliveryValue]);
      setDeliveryValue('');
    }
  };
  const handleRemoveFromList = (itemToRemove) => {
    setList(list.filter((item) => item !== itemToRemove));
  };

  const handleOpenDeliveryReport = async () => {
    setOpenDeliverys(false); // Modal 1'i kapat
    setOpenDeliveryReport(true); // Modal 2'yi aç
    await sendRequests();
  };
  const handleCloseDeliveryReport = () => {
    setOpenDeliveryReport(false);
  };

  const handleProcess = async (item) => {
    try {
      // İlk servis çağrısı
      const getBarcodes = await Services.GetPostByOnlyBarcode(
        parsedUser.token,
        item
      );
      console.log('getBarcodes', getBarcodes);
      let finalResult = []; // Tüm sonuçları toplamak için bir array

      // Barkodların alınması - Eğer status false ise işlemi sonlandır
      if (getBarcodes.data.status === false) {
        const stat = getBarcodes.data.status;
        const message = getBarcodes.data.message;
        finalResult.push({
          stat,
          message,
        });
        return finalResult; // Hata durumu, servis işlemlerine devam edilmez
      }

      // Barkodlar başarıyla alınmışsa işlemlere devam et
      const getBarcode = getBarcodes.data.result.map((e) => ({
        id: e.id,
        status: e.status, // Status bilgisini buradan alıyoruz
      }));
      console.log('getBarcode', getBarcode);

      // İkinci servis çağrısı (her bir barkod için döngü)
      for (const barcodeObj of getBarcode) {
        const barcodeId = barcodeObj.id;
        const barcodeStatus = barcodeObj.status;
        try {
          const res = await Services.SetInBranch(parsedUser.token, barcodeId);
          finalResult.push({
            barcodeId,
            barcodeStatus, // İlk servisten gelen status bilgisi
            setInBranchResult: res.data, // İkinci servisten gelen sonuç
          });

          if (res.data.status) {
            console.log(`Success for barcodeId: ${barcodeId}`);
          } else {
            console.log(`Error for barcodeId: ${barcodeId}`);
          }
        } catch (error) {
          console.error(`Error processing barcodeId: ${barcodeId}`, error);
          toast.error(`Error processing barcodeId: ${barcodeId}`);
        }
      }

      return finalResult;
    } catch (error) {
      console.error(`Error for ${item}:`, error);
      toast.error(`Error processing item: ${item}`);
      throw new Error(`Failed for ${item}`);
    }
  };
  const translationMap = {
    'Post not found': 'Geçersiz Barkod',
  };
  const sendRequests = async () => {
    setLoading(true);
    //console.log('İstekler başlatılıyor...', list);

    let success = 0;
    let failure = 0;

    for (let i = 0; i < list.length; i++) {
      try {
        const data = await handleProcess(list[i]);
        //console.log(`Item ${list[i]} işlemi tamamlandı.`, data);

        // Barkodların status kontrolü (3 olmalı)
        const dataStating = data?.some((e) => e.barcodeStatus === '3'); // Some ile kontrol sağlıyoruz
        //console.log("dataStating", dataStating)

        if (dataStating) {
          setProcessedList((prev) => [
            ...prev,
            {
              item: list[i],
              success: true,
              message: data[0]?.message || 'Başarılı',
            },
          ]);
          success++;
          setSuccessCount(success);
        } else {
          setProcessedList((prev) => {
            const originalMessage = data[0]?.message || 'Yanlış Barkod Durumu';
            const translatedMessage =
              translationMap[originalMessage] ||
              'Barkodun statü durumu geçerli değil.';
            return [
              ...prev,
              { item: list[i], success: false, message: translatedMessage },
            ];
          });
          failure++;
          setFailureCount(failure);
        }
      } catch (error) {
        console.error(`Item ${list[i]} ile ilgili bir hata oluştu:`, error);
        setProcessedList((prev) => [
          ...prev,
          { item: list[i], success: false, message: error.message || 'Hata' },
        ]);
        failure++;
        setFailureCount(failure);
      }
    }

    setLoading(false);
  };

  // const someServiceRequest = (item) => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (Math.random() > 0.2) {
  //         resolve(`Success for ${item}`);
  //       } else {
  //         reject(`Failed for ${item}`);
  //       }
  //     }, 1000); // 1 saniye bekleme
  //   });
  // };

  const handleShowSuccessModal = () => {
    setSuccessModalOpen(true);
    setTimeout(() => {
      setSuccessModalOpen(false);
    }, 5000);
  };

  useEffect(() => {
    if (!loading && failureCount === 0) {
      handleShowSuccessModal();
    }
  }, [loading, failureCount]);

  const exportExcellBarcode = () => {
    let formattedData = processedList.map((x, index) => ({
      id: index + 1,
      barcode_no: x.item,
      barcode_messag: x.message,
      barcode_statu: x.success ? 'Başarılı' : 'Başarısız',
    }));
    api
      .post('excel/export', {
        type: 'excel',
        content: formattedData,
        header: {
          No: 'number',
          Barkod_no: 'string',
          Barkod_mesajı: 'string',
          Barkod_durumu: 'string',
        },
        sheetTitle: 'Barcode',
      })
      .then((response) => {
        if (response.data.status) {
          try {
            const newWindow = window.open(response.data.result, '_blank');
            if (
              !newWindow ||
              newWindow.closed ||
              typeof newWindow.closed == 'undefined'
            ) {
              throw new Error(
                'Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.'
              );
            }
          } catch (e) {
            console.log('export err', e);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const createTicketAuto = async (filteredList) => {
    const formattedList = filteredList.map((item) => {
      return `<b>Barkod Numarası:</b> ${item.item}<br />
              <b>Hata Mesajı:</b> ${item.message}<br />
              <b>Barkod Durmu:</b> ${
                item.success ? 'Teslim Alındı' : 'Teslim Alınmadı'
              }<br /><br />`;
    });
    const messageTicket = formattedList.join('\n');
    await Services.CreateTicket(
      parsedUser.token,
      'Barkod Hataları',
      messageTicket,
      'Report Bug',
      [],
      'zorlucenter@easypoint.com.tr'
    )
      .then((response) => {
        if (response.data.status) {
          console.log('ticket response', response);

          toast.success('Destek Talebiniz Oluşturulmuştur');
        } else {
          toast.warning('Destek Talebiniz Oluşturulmadı');
        }
      })
      .catch((error) => {
        console.log('createTicket', error);
      });
  };
  return (
    <>
      <Helmet>
        <title> {language.all_action_page.titles.site_title} </title>
      </Helmet>

      <div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          paddingX={2}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '2%',
              width: '100%',
            }}
          >
            <Typography variant="h4" gutterBottom>
              {language.all_action_page.titles.title}
            </Typography>
          </div>
          <Button
            onClick={handleOpenDelivery}
            sx={{
              width: 170,
              height: 40,
              backgroundColor: '#079455',
              fontSize: 14,
              fontFamily: 'Inter',
              fontWeight: 500,
              borderRadius: '5px',
              padding: '8px 16px 8px 16px',
              boxShadow: '0px 6px 12px rgba(8, 138, 178, 0.5)',
              gap: '6px',
              '&:hover': {
                backgroundColor: '#067f49',
              },
            }}
            variant="contained"
          >
            Toplu Teslim Al
          </Button>
          <ExportToExcelButton
            setShowPopUpError={setShowPopUpError}
            showPopUpError={showPopUpError}
            sx={{ marginLeft: '8px' }}
            onClick={() => exportToExcel()}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
          paddingX={2}
        >
          {isMobile ? (
            <FilterViewButton variant="contained" onClick={handleModal} />
          ) : null}
        </Stack>
        <Modal
          open={openDeliverys}
          onClose={handleCloseDelivery}
          aria-labelledby="modal-delivery-title"
          aria-describedby="modal-delivery-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 650,
              bgcolor: '#FBFBFB',
              border: '1px solid #FBFBFB',
              boxShadow: 24,
              p: 4,
              borderRadius: '6px',
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: '#C2C0C0',
              }}
              onClick={handleCloseDelivery}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              id="modal-title"
              variant="h6"
              component="h2"
              color="#C3C3C3"
            >
              Barkod Giriniz
            </Typography>
            <Stack
              flexDirection="row"
              sx={{ gap: '10px', mt: 2 }}
              alignItems="center"
              justifyContent="center"
            >
              {/* <Autocomplete
                sx={{ flex: 3 }}
                value={inputValue}
                onInputChange={handleInputChange}
                options={list} // Listeyi autocomplete seçenekleri olarak kullan
                renderInput={(params) => <TextField {...params} label="Barkod Kodu Giriniz" variant="outlined" />}
                freeSolo
              /> */}
              <TextField
                sx={{ flex: 3 }}
                value={deliveryValue}
                onChange={handleInputChange}
                label="Barkod Kodu Giriniz"
                variant="outlined"
              />
              {/* Add to list button */}
              <Button
                sx={{
                  flex: 1,
                  height: '45px',
                  fontWeight: 500,
                }}
                variant="contained"
                onClick={handleAddToList}
              >
                Barkod Ekle
              </Button>
            </Stack>
            <Stack>
              {/* Liste */}
              {list.length > 0 && (
                <List
                  sx={{
                    mt: 2,
                    width: '100%',
                    backgroundColor: '#F8F8F8',
                    border: '1px solid #EEEEEE',
                    borderRadius: '6px',
                    display: 'grid', // Grid düzeni kullanıyoruz
                    maxHeight: '500px',
                    overflowY: 'auto',
                  }}
                >
                  {list.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{ gap: 2, alignItems: 'center', color: '#5D5D5D' }}
                    >
                      {index + 1 + '.'}
                      <Stack
                        alignItems="center"
                        flexDirection="row"
                        sx={{
                          border: '1px solid #EEEEEE',
                          flex: 2,
                          backgroundColor: '#F8F9FC',
                          borderRadius: '16px',
                          width: '150px',
                        }}
                      >
                        <ListItemText
                          sx={{ m: 0, paddingLeft: 2 }}
                          primary={item}
                        />
                        <IconButton
                          sx={{
                            paddingRight: 3,
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          edge="end"
                          aria-label="remove"
                          onClick={() => handleRemoveFromList(item)}
                        >
                          <DeleteOutlineIcon sx={{ p: 0 }} />
                        </IconButton>
                      </Stack>
                    </ListItem>
                  ))}
                </List>
              )}
            </Stack>
            {list.length > 0 && (
              <Stack mt={2} alignItems="center">
                <Button
                  onClick={handleOpenDeliveryReport}
                  sx={{
                    width: 170,
                    height: 40,
                    backgroundColor: '#079455',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    borderRadius: '5px',
                    padding: '8px 16px 8px 16px',
                    boxShadow: '0px 6px 12px rgba(8, 138, 178, 0.5)',
                    gap: '6px',
                    '&:hover': {
                      backgroundColor: '#067f49',
                    },
                  }}
                  variant="contained"
                >
                  Toplu Teslim Al
                </Button>
              </Stack>
            )}
          </Box>
        </Modal>
        <Modal
          open={openDeliveryReport}
          onClose={handleCloseDeliveryReport}
          aria-labelledby="modal-delivery-title"
          aria-describedby="modal-delivery-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 650,
              bgcolor: '#FBFBFB',
              border: '1px solid #FBFBFB',
              boxShadow: 24,
              p: 4,
              borderRadius: '6px',
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: '#C2C0C0',
              }}
              onClick={handleCloseDeliveryReport}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 40,
                color: '#C2C0C0',
              }}
              onClick={exportExcellBarcode}
            >
              <Download />
            </IconButton>
            <Typography
              id="modal-title"
              variant="h6"
              component="h2"
              color="#C3C3C3"
              mb={2}
            >
              Toplu Teslim Raporu
            </Typography>
            <Divider sx={{ borderStyle: 'dot', backgroundColor: '#F7E0E0' }} />
            <Stack
              overflow={processedList.length > 0 ? 'auto' : 'hidden'}
              maxHeight="500px"
            >
              <ul style={{ paddingTop: '15px' }}>
                {processedList.length > 0 ? (
                  processedList.map((e, index) => (
                    <li style={{ listStyleType: 'none' }} key={index}>
                      {e.success ? (
                        <DotsColor color="green" />
                      ) : (
                        <DotsColor color="#DC6803" />
                      )}
                      {e.item} - {e.message}
                    </li>
                  ))
                ) : (
                  <li>
                    <CircularProgress />
                  </li>
                )}
              </ul>
              <Snackbar
                sx={{
                  '& .MuiSnackbarContent-root': {
                    marginBottom: '-100px',
                    backgroundColor: '#4caf50',
                  },
                }}
                open={loading}
                message={`İşlem devam ediyor lütfen ekranınızı kapatmayınız: ${processedList.length}/${list.length}`}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              />
            </Stack>
            <Stack pt={2}>
              <Typography
                id="modal-title"
                variant="p"
                component="p"
                color="#636364"
              >
                <span style={{ color: '#079455' }}>{successCount} adet </span>
                barkod teslim alınmıştır.
              </Typography>
              <Typography
                id="modal-title"
                variant="p"
                component="p"
                color="#636364"
              >
                <span style={{ color: '#DC6803' }}>{failureCount} adet </span>
                barkod teslim alınamamıştır.
              </Typography>
            </Stack>
            <Stack pt={2}>
              {!loading &&
                (failureCount > 0 ? (
                  <Stack flexDirection="row" justifyContent="center" gap={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        // processedList'teki success: true olanları filtrele
                        const failedItems = processedList.filter(
                          (e) => !e.success
                        );
                        setProcessedList(failedItems);

                        setSuccessCount(0);
                      }}
                    >
                      Başarılı İşlemleri Temizle
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        console.log('destek talep', processedList);
                        const filteredList = processedList.filter(
                          (e) => !e.success
                        );
                        await createTicketAuto(filteredList);
                      }}
                    >
                      Destek Talebi Oluştur
                    </Button>
                  </Stack>
                ) : (
                  <Modal
                    open={successModalOpen}
                    onClose={() => setSuccessModalOpen(false)}
                    aria-labelledby="success-modal-title"
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'white',
                        border: '2px solid #079455',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <CheckIcon
                        sx={{ fontSize: 50, color: '#079455', mb: 2 }}
                      />

                      <Typography
                        id="success-modal-title"
                        variant="h6"
                        component="h2"
                        color="#079455"
                      >
                        Tüm işlemler başarıyla tamamlandı!
                      </Typography>
                    </Box>
                  </Modal>
                ))}
            </Stack>
          </Box>
        </Modal>
        <Modal
          open={mobileAll}
          onClose={handleMobileAllClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box component="form" sx={all}>
            <Typography
              style={{ fontWeight: '600', fontSize: '18px', color: '#1F2A37' }}
              component="div"
            >
              {language.all_action_page.titles.filters}
            </Typography>
            <hr />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                marginBottom: '3%',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label={language.commons.labels.start_date}
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label={language.commons.labels.end_date}
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                marginBottom: '3%',
              }}
            >
              <TextField
                label={language.commons.column.barcode}
                variant="outlined"
                autoComplete="off"
                value={barcode}
                onChange={(e) => filterByBarcode(e.target.value)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                marginBottom: '3%',
              }}
            >
              <Stack style={{ width: '50%' }}>
                <FormControl>
                  <Select
                    multiple
                    displayEmpty
                    value={selectedCargoStatus}
                    input={<OutlinedInput />}
                    onChange={handleChange}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <em>{language.commons.status}</em>;
                      }

                      return selected.map((x) => x?.label).join(', ');
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {StatusList.map((x) => (
                      <MenuItem key={x.id} value={x}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                marginBottom: '3%',
              }}
            >
              <TextField
                style={{ width: '50%' }}
                label={language.commons.column.branch_code}
                variant="outlined"
                autoComplete="off"
                value={epBranchCode}
                onChange={(e) => filterByBranchCode(e.target.value)}
              />
            </div>
            <div
              style={{
                height: '20px',
                width: '1px',
                backgroundColor: '#EAECF0',
                margin: '0 15px',
                marginLeft: '-2px',
              }}
            ></div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ClearButton onClick={() => clearFilters()} />
              <FilterButton onClick={() => getFilteredOrderList()} />
            </div>
          </Box>
        </Modal>

        <Card>
          <Stack
            direction="row"
            paddingLeft={3}
            paddingRight={3}
            paddingTop={3}
            spacing={1}
            alignItems="center"
          >
            <ErrorOutlineIcon sx={{ fontSize: 20 }} />
            <div>{language.all_action_page.titles.post_filter}</div>
          </Stack>
          <CustomFilter
            modalType={'drawer'}
            filterModal={filterModal}
            handleCloseModal={handleCloseModal}
          >
            <Typography sx={{ marginLeft: 1, marginTop: 2 }}>
              {language.all_action_page.titles.post_list}
            </Typography>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={2}
              paddingX={1}
              spacing={2}
              width="100%"
            >
              <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                <FormControl sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      sx={{ flex: 1 }}
                      format="DD/MM/YYYY"
                      label={language.commons.labels.start_date}
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      sx={{ flex: 1 }}
                      format="DD/MM/YYYY"
                      label={language.commons.labels.end_date}
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Stack>

              <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                <FormControl sx={{ flex: 1 }}>
                  <Select
                    multiple
                    displayEmpty
                    value={selectedCargoStatus}
                    input={<OutlinedInput />}
                    onChange={handleChange}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <em>{language.commons.status}</em>;
                      }

                      return selected.map((x) => x?.label).join(', ');
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {StatusList.map((x) => (
                      <MenuItem key={x.id} value={x}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ flex: 1 }}>
                  <InputLabel id="demo-simple-select-label">
                    {language.commons.shipment_type}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={apiTypes}
                    label="Gönderi Tipi"
                    onChange={(e) => setApiTypes(e.target.value)}
                  >
                    <MenuItem value={'handsfree'}>
                      {language.commons.brands.handsfree}
                    </MenuItem>
                    <MenuItem value={'hepsiburada'}>
                      {language.commons.brands.hepsiburada}
                    </MenuItem>
                    <MenuItem value={'trendyol'}>
                      {language.commons.brands.trendyol}
                    </MenuItem>
                    <MenuItem value={'aras-kargo'}>
                      {language.commons.brands.araskargo}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>

              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <Autocomplete
                  noOptionsText={language.all_action_page.titles.no_match}
                  value={
                    pointTitlesOptions.find(
                      (option) => option.id === selectedPoint
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setSelectedPoint(newValue ? newValue.id : null);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={pointTitlesOptions}
                  getOptionLabel={(option) => option.title}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  sx={{ flex: 1 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language.commons.labels.easypoint}
                    />
                  )}
                />
              </FormControl>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              my={2}
              spacing={1}
              paddingX={1}
            >
              <ClearButton onClick={() => clearFilters()} />
              <FilterButton
                label={language.commons.labels.list}
                onClick={() => getFilteredOrderList()}
              />
            </Stack>

            <HorizontalDivider />
            {secondFilter && (
              <>
                <Typography sx={{ marginLeft: 1, marginTop: 2 }}>
                  {language.all_action_page.titles.post_filter_empty}
                </Typography>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                      label={language.commons.column.barcode}
                      variant="outlined"
                      autoComplete="off"
                      value={barcode}
                      onChange={(e) => setBarcode(e.target.value.trim())}
                      sx={{ my: 2, marginLeft: 1 }}
                    />
                  </FormControl>

                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                      label={language.commons.labels.order_no}
                      variant="outlined"
                      autoComplete="off"
                      value={orderNo}
                      onChange={(e) => setOrderNo(e.target.value)}
                      sx={{ my: 2, marginLeft: 1 }}
                    />
                  </FormControl>
                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                      label={language.commons.column.receiver}
                      variant="outlined"
                      autoComplete="off"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      sx={{ my: 2, marginLeft: 1 }}
                    />
                  </FormControl>

                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                      label={language.commons.column.branch_code}
                      variant="outlined"
                      autoComplete="off"
                      value={epBranchCode}
                      onChange={(e) => setEpBranchCode(e.target.value)}
                      sx={{ my: 2, marginLeft: 1 }}
                    />
                  </FormControl>

                  <div
                    style={{
                      height: '20px',
                      width: '1px',
                      backgroundColor: '#EAECF0',
                      marginLeft: '15px',
                    }}
                  ></div>
                  <Stack direction="row" justifyContent="center">
                    <ClearButton onClick={clearFilterAndInputs} />
                    <FilterButton onClick={() => getFilteredActionList()} />
                  </Stack>
                </Stack>
              </>
            )}
          </CustomFilter>

          <DynamicTable
            data={filteredOrders}
            headers={TABLE_HEAD}
            initialOrder={'name'}
            initalOrderDirection={'desc'}
            isOrdered={true}
            isMobile={isMobile}
            handleModal={handleModal}
            handleCloseModal={handleCloseModal}
            rowOnClick={(row) =>
              window.open(`/dashboard/post-details/${row.barcode}`, '_blank')
            }
          />
        </Card>
      </div>
    </>
  );
}
