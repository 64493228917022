import { Stack, TextField, Typography, InputAdornment, IconButton, Button, Backdrop, CircularProgress, Modal, Box } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import Services from 'src/services/Services';
import successImage from '../../assets/images/success-check.png';
import errorImage from '../../assets/images/error.png';
import { toast } from 'react-toastify';
import { useLocalization } from 'src/context/LocalizationContext';

export default function CargoPartnerTakeOrderPage() {
    const { partner } = useParams();
    const { language } = useLocalization();
    const [customerQR, setCustomerQR] = useState('');
    const [foundOrder, setFoundOrder] = useState('');
    const [barcode, setBarcode] = useState('');
    const [loading, setLoading] = useState(false);
    const [showBarcodeInput, setShowBarcodeInput] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalImage, setModalImage] = useState('');

    let partnerName = ""

    if (partner === "aras-kargo") {
        partnerName = "Aras Kargo"
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCustomerQR('')
        setFoundOrder('')
        setBarcode('')
    };

    const allFieldsAreValid = () => {
        return customerQR.trim() !== '' && foundOrder !== '' && barcode.trim().length === 10;
    };

    const handleCompleteOrderRequest = () => {
        setLoading(true)
        const parsedUser = JSON.parse(localStorage.getItem('user'));
        const token = parsedUser.token;

        Services.ArasKargoDropOffAccept(token, customerQR, barcode)
            .then((res) => {
                setLoading(false);
                if (res.data.status) {
                    setModalMessage('Gönderi Alma işlemini başarıyla tamamlanmıştır.');
                    setModalImage(successImage);
                } else {
                    setModalMessage(res.data.message ? res.data.message : 'Gönderi Alma işlemi sırasında bir hata oluştu.');
                    setModalImage(errorImage);
                }
                setIsModalOpen(true);
            })
            .catch((err) => {
                setLoading(false);
                setModalMessage(res.data.message ? res.data.message : 'Gönderi Alma işlemi sırasında bir hata oluştu.');
                setModalImage(errorImage);
                setIsModalOpen(true);
            });
    }

    const handleQuery = () => {
        setLoading(true)
        const parsedUser = JSON.parse(localStorage.getItem('user'));
        const token = parsedUser.token;

        Services.ArasKargoDropOffQrCheck(token, customerQR)
            .then((res) => {
                setLoading(false);
                if (res.data.status) {
                    setFoundOrder(res.data.result)
                    setShowBarcodeInput(true)
                    setLoading(false)
                } else {
                    setFoundOrder("Barkod bulunamadı.")
                    setShowBarcodeInput(false)
                    setLoading(false)
                    toast.error("Gönderi bulunamadı.")
                }
            })
            .catch((err) => {
                setFoundOrder("Barkod bulunamadı.")
                setShowBarcodeInput(false)
                setLoading(false)
            });
    };

    return (
        <>
            <Helmet>
                <title>{language.cargo_partner_operations.titles.site_title}</title>
            </Helmet>

            <Stack style={{ paddingInline: '25px' }}>
                <Stack
                    direction="column"
                    alignItems="flex-start"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        {language.cargo_partner_operations.titles.title}
                    </Typography>
                    <p style={{ color: '#D1D1D1', fontSize: '16px', fontWeight: '400' }}>
                        {partnerName} - {language.commons.labels.receive_shipment}
                    </p>
                    <HorizontalDivider />
                </Stack>
                {loading ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 2, height: '100%' }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    :
                    <>
                        <p style={{ color: 'black', fontWeight: '700', fontSize: '14px' }}>{language.commons.labels.customer_qr}:</p>
                        <TextField
                            placeholder={language.commons.labels.customer_qr}
                            variant="outlined"
                            fullWidth
                            value={customerQR}
                            onChange={(e) => setCustomerQR(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" sx={{ marginLeft: 'auto' }}>
                                        <Button
                                            disabled={!customerQR}
                                            sx={{
                                                position: 'absolute',
                                                backgroundColor: '#DC423C',
                                                color: 'white',
                                                height: '54px',
                                                width: '90px',
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0,
                                                right: '0',
                                                display: 'flex',
                                                fontSize: '11px',
                                                fontFamily: 'Inter, sans-serif',
                                                fontWeight: '600',
                                                '&:hover': {
                                                    backgroundColor: '#B53832'
                                                },
                                                '&.Mui-disabled': {
                                                    backgroundColor: '#DDDFE3',
                                                },
                                            }}
                                            onClick={handleQuery}
                                        >
                                            {language.commons.labels.query}
                                        </Button>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/* <span style={{ color: '#667085', fontSize: '12px', fontWeight: '400', marginTop: '5px' }}>Elle yazarak sorgulayabilir veya QR kodu okutabilirsiniz.</span> */}

                        {showBarcodeInput &&
                            <>
                                <div style={{ width: '100%', height: '64px', borderRadius: '6px', border: '1px solid #E4E4E4', marginTop: '45px', padding: '11px', paddingRight: '22px', paddingLeft: '22px' }}>
                                    <span style={{ fontWeight: '700', fontSize: '16px' }}>{foundOrder}</span>
                                    <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                                        <span style={{ fontSize: '14px', fontWeight: '600', color: '#787878' }}>
                                            {partnerName}
                                        </span>
                                    </div>
                                </div>

                                <p style={{ color: 'black', fontWeight: '700', fontSize: '14px', marginTop: '34px' }}>{language.commons.enter_barcode}:</p>
                                <TextField
                                    placeholder='Barkod'
                                    variant="outlined"
                                    fullWidth
                                    value={barcode}
                                    onChange={(e) => setBarcode(e.target.value)}
                                    inputProps={{ maxLength: 10 }}
                                />
                                {barcode.length !== 10 &&
                                    <span style={{ color: '#da3831', fontSize: '12px', fontWeight: '400', marginTop: '5px' }}>{language.commons.barcode_must_10_char}</span>
                                }
                            </>
                        }
                        <Button
                            onClick={handleCompleteOrderRequest}
                            style={{ marginTop: '50px' }}
                            sx={{
                                width: '100%',
                                backgroundColor: '#DC423C',
                                color: 'white',
                                marginTop: '21px',
                                height: '54px',
                                display: 'flex',
                                '&:hover': {
                                    backgroundColor: '#B53832'
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#DDDFE3',
                                },
                            }}
                            disabled={!allFieldsAreValid()}
                        >
                            {language.commons.labels.complete_take_order}
                        </Button>

                    </>
                }

                <Modal
                    open={isModalOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <img src={modalImage} alt="Sonuç" style={{ width: '60px', height: '60px', marginBottom: '20px' }} />
                        <Typography id="modal-modal-description" sx={{ mt: 1, fontSize: '15px', fontWeight: '700', width: '200px', textAlign: 'center' }}>
                            {modalMessage}
                        </Typography>
                        <Button onClick={handleCloseModal}
                            sx={{
                                width: '100%',
                                backgroundColor: '#DC423C',
                                color: 'white',
                                marginTop: '21px',
                                height: '54px',
                                display: 'flex',
                                '&:hover': {
                                    backgroundColor: '#B53832'
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#DDDFE3',
                                },
                            }} fullWidth>
                            {language.commons.labels.take_new_order}
                        </Button>
                    </Box>
                </Modal>

            </Stack>
        </>
    );
}
