import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import './pointsmappage.css';
import resim from '../assets/images/easy_logo.png';
import info from '../assets/images/infoo.png';
import location_logo from '../assets/images/location.png';
import { CityList, Points } from '../data/CityList';
import TextField from '@mui/material/TextField';
import districtUrl from '../data/District.json';

import SelectSpec from '../components/selectSpec/SelectSpec';
import Services from 'src/services/Services';

const markerIconSize = {
  width: 35,
  height: 35,
};

const containerStyle = {
  width: '100%',
  height: '130vh',
};

const center = {
  lat: 39.9334,
  lng: 32.8597,
};

function PointsMapPage() {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [fetchedLocations, setFetchedLocations] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [csFilter, setCsFilter] = useState([]);
  const [lastFilterList, setLastFilterList] = useState([]);
  const [cityName, setCityName] = useState('İstanbul');
  const [pointName, setPointName] = useState('Hepsi');
  const [districtName, setDistrictName] = useState('');
  const [officeName, setOfficeName] = useState('');

  const districtFilter = districtUrl.filter((item) => {
    return item?.text?.toLocaleLowerCase() === cityName?.toLocaleLowerCase();
  });

  const openMenu = () => {
    setIsOpen(!isOpen);
  };

  let AmazonName = '1';

  const handleResetSearchBar = () => {
    setOfficeName('');
  };

  const clearFilters = () => {
    setCityName('');
    setPointName('');
    setDistrictName('');
    setOfficeName('');
  };

  const getFilteredPointList = () => {
    let filteredList;
    const hepsiSelected = pointName === 'Hepsi';

    if (hepsiSelected || cityName || districtName || AmazonName || officeName) {
      filteredList = csFilter.filter((item) => {
        const cityNameMatch = cityName
          ? item.city?.toLowerCase().includes(cityName?.toLowerCase())
          : true;
        const amazonNameMatch = hepsiSelected
          ? item['Box'] === '1' ||
            item['EasyPoint'] === '1' ||
            item['Esnaf'] === '1'
          : pointName
          ? item[pointName] === '1'
          : true;

        const districtLower = districtName.toLocaleLowerCase('tr');
        const itemDistrictLower = item.district?.toLocaleLowerCase('tr');

        //console.log(`districtLower: '${districtLower}', itemDistrictLower: '${itemDistrictLower}'`);

        const districtNameMatch = districtName
          ? item.district
              ?.toLocaleLowerCase('tr')
              .includes(districtName.toLocaleLowerCase('tr'))
          : true;
        const officeNameMatch = officeName
          ? item.locator_name?.toLowerCase().includes(officeName?.toLowerCase())
          : true;
        return (
          cityNameMatch &&
          amazonNameMatch &&
          districtNameMatch &&
          officeNameMatch
        );
      });
    } else {
      filteredList = csFilter;
    }

    //console.log("filteredList",filteredList);
    setLastFilterList(filteredList);
  };

  //flow/get-points

  const fetchPoints = () => {
    Services.GetToken().then((res) => {
      //console.log(res.data);
      if (res.data.status) {
        Services.GetPoints(res.data.result.token)
          .then((response) => {
            //console.log(response.data);
            if (response.data.status) {
              const selectedData = response?.data?.result?.map((item) => ({
                id: item.id,
                locator_name: item.title,
                name: item.city,
                amazonTitle: item.amazonTitle,
                location: {
                  lat: parseFloat(item.latitude),
                  lng: parseFloat(item.longitude),
                },
                city: item.city,
                district: item.district,
                //Amazon:item.isAmazon,
                Box: item.isBox,
                EasyPoint: item.isEasyPoint,
                Esnaf: item.isEsnaf,
                //Hepsiburada:item.isHepsiburada,
                //MalKabul:item.isMalKabul,
                //Muhaberat:item.isMuhaberat,
                //N11:item.isN11,
                //Trendyol:item.isTrendyol,
                mallID: item.mallID,
                workingDays: item.workingDays,
                amazonAddress1: item.amazonAddress1,
                amazonAddress2: item.amazonAddress2,
              }));

              setCsFilter(selectedData);
              setFetchedLocations(selectedData);
            }
          })
          .catch((error) => console.log(error));
      }
    });
  };

  useEffect(() => {
    fetchPoints();
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC7Yo4dlBRWcC_XUbTH2IEgYdPLsvHJZzs',
    //Stage = AIzaSyDWq6KQ1B7S18jnWhuSC6-gDj4JOij4RgQ
    //Prod  = AIzaSyC7Yo4dlBRWcC_XUbTH2IEgYdPLsvHJZzs
  });

  const onMarkerClick = (location, index) => {
    setSelectedLocation(location);
    setSelectedCardIndex(index);
  };

  const onCloseClick = () => {
    setSelectedLocation(null);
    setSelectedCardIndex(null);
  };

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(6);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleOfficeName = (e) => {
    const { value } = e.target;
    setOfficeName(value);
  };

  //console.log('Filtre Listesi', lastFilterList);

  return isLoaded ? (
    <div className="container d-flex">
      <div className={`mobil_menu left md-none `}>
        <img
          className="img_logo"
          width={200}
          src={resim}
          alt="Easypoint Logo"
          onClick={openMenu}
        />
        {isOpen ? (
          <div className="icon-close" onClick={openMenu}></div>
        ) : (
          <div className="icon-menu" onClick={openMenu}></div>
        )}
      </div>
      <div className={`left_column flex-1 ${isOpen ? 'spar' : 'mobile-none'} `}>
        <div className="p-3 mt-4">
          <div className="p-4 text-align-center">
            <hr className="custom-hr"></hr>
          </div>
          <div className="box-no-show">Easy Point Noktaları</div>

          <label className="info_logo mt-4 p-1">
            Easy Point Noktası Seçin
            <img
              style={{ display: 'inline-block', marginLeft: 5 }}
              src={info}
              alt="info logo"
            ></img>
          </label>

          <SelectSpec
            onChange={setPointName}
            selectedValue={pointName}
            //variable={{pointName, setPointName}}
            name={'nokta'}
            customArray={Points}
            placeTitle="Easy Point Noktası Seçiniz"
            index={'1'}
          />

          <label className="info_logo mt-4 p-1">İl Seçin </label>
          <SelectSpec
            //variable={{cityName,setCityName}}
            onChange={setCityName}
            selectedValue={cityName}
            name={'il'}
            customArray={CityList}
            placeTitle="İl Seçiniz"
            index={'2'}
          />

          <label className="info_logo mt-4 p-1">İlçe Seçin </label>
          <SelectSpec
            onChange={setDistrictName}
            selectedValue={districtName}
            name={'ilce'}
            customArray={districtFilter}
            placeTitle="İlçe Seçiniz"
            index={'3'}
            disablek={cityName ? false : true}
          />
          <div className="animated zoomIn">
            <label className="info_logo mt-4 p-1">Şube Adı Arayın </label>
            {/* <input onChange={handleOfficeName} placeholder='Şube Adı Arayın' className='searchbar' type="search" name="searcher"></input> */}
            <TextField
              placeholder="Şube Adı Arayın"
              value={officeName}
              onChange={handleOfficeName}
              id="outlined-basic"
              variant="outlined"
              className="searchbar"
            />

            {officeName.length > 0 ? (
              <div
                style={{ position: 'absolute', right: '10px', top: '60%' }}
                className="icon-close"
                onClick={handleResetSearchBar}
              ></div>
            ) : (
              ''
            )}
          </div>
          <div className="animated zoomIn buttons m-4 text-align-center">
            <button
              className="custom-btn filter-btn"
              onClick={getFilteredPointList}
            >
              Filtrele
            </button>
            <button className="custom-btn clear-btn" onClick={clearFilters}>
              Temizle
            </button>
          </div>
        </div>
      </div>
      <div className={`right_column ${isOpen ? 'flex-0' : 'flex-3'} `}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={6}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {lastFilterList?.map((location, index) => (
            <MarkerF
              icon={{
                url: location_logo,
                scaledSize: markerIconSize,
              }}
              key={index}
              onClick={() => onMarkerClick(location, index)}
              position={location.location}
            />
          ))}
          {selectedLocation && (
            <InfoWindowF
              position={selectedLocation.location}
              onCloseClick={onCloseClick}
            >
              <div className="heroko">
                <h2>{selectedLocation.locator_name}</h2>
                <h3>
                  <span style={{ fontWeight: 'bold', color: '#DC423C' }}>
                    Şehir:
                  </span>
                  {selectedLocation.name}
                </h3>
                <h4>
                  <span style={{ fontWeight: 'bold', color: '#DC423C' }}>
                    Adres:
                  </span>{' '}
                  {selectedLocation.amazonAddress1}{' '}
                  {selectedLocation.amazonAddress2}
                </h4>
                <h4>
                  <span style={{ fontWeight: 'bold', color: '#DC423C' }}>
                    Çalışma Saatleri:
                  </span>{' '}
                  {selectedLocation.workingDays.friday.openingTime.hour}:
                  {selectedLocation.workingDays.friday.openingTime.minute} -{' '}
                  {selectedLocation.workingDays.friday.closingTime.hour}:
                  {selectedLocation.workingDays.friday.closingTime.minute}
                </h4>
                <p>{selectedLocation.description}</p>
              </div>
            </InfoWindowF>
          )}
        </GoogleMap>
      </div>
    </div>
  ) : null;
}

export default PointsMapPage;
