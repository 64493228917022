import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectSpec = ({
  name,
  customArray,
  placeTitle,
  index,
  selectedValue,
  onChange,
  disablek,
}) => {
  if (name === 'ilce') {
    const handleChange = (event) => {
      const value = event.target.value;
      //setSelectedValue(value);
      //variable?.setCityName(value);
      onChange(value);
    };

    return (
      <div className="select animated zoomIn mb-4">
        <FormControl fullWidth disabled={disablek}>
          <Select
            className="custom-selector custom-select"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedValue}
            onChange={handleChange}
            displayEmpty
          >
            <MenuItem value="">
              Tümü
            </MenuItem>
            {customArray[0]?.districts?.map((option, index) => (
              <MenuItem
                key={option.value}
                className="custom-menu option"
                value={option?.text}
              >
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  } else {
    const handleChange = (event) => {
      const value = event.target.value;
      //setSelectedValue(value);
      //variable?.setCityName(value);
      onChange(value);
    };

    return (
      <div className="select animated zoomIn mb-4">
        <FormControl fullWidth>
          <Select
            className="custom-selector custom-select"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedValue}
            onChange={handleChange}
            displayEmpty
          >
            <MenuItem value="">
              Hepsi
            </MenuItem>
            {customArray.map((option, index) => (
              <MenuItem
                key={option.id}
                className="custom-menu option"
                value={option.name}
              >
                {option.name?.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
};

export default SelectSpec;
