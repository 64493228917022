import React, { useState } from 'react';
import { Stack, Typography, TextField, Button } from '@mui/material';

export default function SearchByBarcode() {
  const [barcode, setBarcode] = useState('');

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      mb={5}
    >
      <TextField
        id="barcode"
        name="barcode"
        type="number"
        label="Barkod giriniz"
        fullWidth
        value={barcode}
        style={{ backgroundColor: 'white' }}
        onChange={(e) => setBarcode(e.target.value.trim())}
      />
      <Button
        style={{ marginLeft: 10 }}
        variant="contained"
        color="primary"
        size="large"
      >
        Ara
      </Button>
    </Stack>
  );
}
